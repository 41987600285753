import { createRoot } from 'react-dom/client';

import sentryInit from 'app/components/sentry/sentryInit';
import SentryWrapper from 'app/components/sentry/SentryWrapper';

import AppComponent from './App';

// Mount the app
const root = document.getElementById('root');

sentryInit();

if (root) {
  createRoot(root).render(
    <SentryWrapper>
      <AppComponent />
    </SentryWrapper>
  );
}
