import { FC } from 'react';
import { LiaCitySolid, LiaIndustrySolid, LiaUsersCogSolid } from 'react-icons/lia';
import { Link } from 'react-router-dom';

import oceanLogo from 'app/assets/svg/ocean-logo.svg';
import CustomTooltip from 'core/components/CustomTooltip';
import t from 'core/helpers/t';

import styles from './Sidebar.module.css';

const Sidebar: FC = () => {
  return (
    <div className={styles.sidebar}>
      <Link className={styles.logo} to="/">
        <img alt="Ocean Logo" src={oceanLogo} />
      </Link>
      <nav className={styles.nav}>
        <CustomTooltip message={t('Dodávatelia')}>
          <Link className={styles.link} to="/suppliers">
            <LiaIndustrySolid className={styles.icon} />
          </Link>
        </CustomTooltip>

        <CustomTooltip message={t('Zoznam spoločností')}>
          <Link className={styles.link} to="/companies">
            <LiaCitySolid className={styles.icon} />
          </Link>
        </CustomTooltip>

        <CustomTooltip message={t('Správa používateľov')}>
          <Link className={styles.link} to="/users">
            <LiaUsersCogSolid className={styles.icon} />
          </Link>
        </CustomTooltip>
      </nav>
    </div>
  );
};

export default Sidebar;
