import clsx from 'clsx';
import { useState, useEffect, useRef } from 'react';
import { LiaUserCircle } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useLogout from 'core/auth/hooks/useLogout';
import CustomToast from 'core/components/CustomToast';
import t from 'core/helpers/t';

import styles from './TopBar.module.css';

export default function TopBar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const logout = useLogout();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className={styles.topBar}>
      <div className={styles.leftSection}>
        <div className="ml-2">
          <div className={styles.title}>
            <h1>{t('Zoznam dodávateľov')}</h1>
          </div>
          <div className="text-neutral-400">
            <small>{t('Ocean / Zoznam dodávateľov')}</small>
          </div>
        </div>
      </div>
      <div ref={dropdownRef} className={styles.dropdownContainer}>
        <LiaUserCircle
          className="size-10 cursor-pointer text-primary-500"
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
        />
        {dropdownOpen && (
          <div className={styles.dropdownMenu}>
            <button
              disabled
              className={clsx(
                styles.dropdownItem,
                'disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-neutral-500 disabled:opacity-50'
              )}
              onClick={() => {
                navigate('/profile');
                setDropdownOpen(false);
              }}
            >
              {t('Profil')}
            </button>

            <button
              className={styles.dropdownItem}
              onClick={() => {
                void logout().then((loggedOut) => {
                  toast(
                    <CustomToast
                      message={loggedOut ? t('Úspešne odhlásený') : t('Nepodarilo sa odhlásiť')}
                      type={loggedOut ? 'success' : 'error'}
                    />
                  );
                });
              }}
            >
              {t('Odhlásiť sa')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
