import Tippy, { TippyProps } from '@tippyjs/react';
import React, { ReactElement } from 'react';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';

import './CustomTooltip.css';

interface CustomTooltipProps extends Partial<TippyProps> {
  message: string;
  children: ReactElement;
}

export default function CustomTooltip({
  message,
  children,
  placement = 'top',
  animation = 'scale',
  theme = 'custom',
  delay = [50, 50],
  arrow = true,
  ...props
}: CustomTooltipProps) {
  return (
    <Tippy
      animation={animation}
      arrow={arrow}
      content={message}
      delay={delay}
      placement={placement}
      theme={theme}
      {...props}
    >
      {children}
    </Tippy>
  );
}
