import { Navigate, Outlet } from 'react-router-dom';

import Sidebar from 'app/components/SideBar';
import TopBar from 'app/components/TopBar';
import useUser from 'core/auth/hooks/useUser';
import 'app/extendYup';

export default function AppLayout() {
  const user = useUser();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Sidebar />

      <main className="ml-20">
        <TopBar />
        <div className="pt-24">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
