import * as Sentry from '@sentry/react';
import { useCallback } from 'react';

import { UserModel } from '../../../app/api/requests';

/**
 * Request user profile
 */
export default function useGetProfile() {
  return useCallback((accessToken?: string) => {
    return accessToken
      ? fetch(`${import.meta.env.VITE_APP_API_PATH}/auth/me`, {
          method: 'POST',
          headers: { Accept: 'application/json', Authorization: `Bearer ${accessToken}` },
        })
          .then((r) => (r.ok ? (r.json() as Promise<UserModel | undefined>) : Promise.resolve(undefined)))
          .then((u) => {
            Sentry.setUser({ email: u?.email, id: u?.id });
            return u;
          })
      : Promise.resolve(undefined);
  }, []);
}
