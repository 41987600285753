import { createContext, Dispatch, SetStateAction } from 'react';

import { LoginResponse } from './hooks/useLogin';
import { UserModel } from 'app/api/requests';

export interface AuthContextState {
  user: UserModel;
  token: LoginResponse;
}

/**
 * Auth Context Value
 */
const AuthContext = createContext<AuthContextState | undefined | null>(undefined);

/**
 * Auth Context setter, eliminate context re-renders and
 */
const SetAuthContext = createContext<Dispatch<SetStateAction<AuthContextState | undefined | null>>>(() => {
  //
});

export { SetAuthContext };

export default AuthContext;
