import { PropsWithChildren } from 'react';

export default function Version({ children }: PropsWithChildren) {
  const version = import.meta.env.VITE_APP_VERSION;

  return (
    <>
      {children}
      {version && <div className="fixed bottom-2 right-2 text-sm text-neutral-300">{version}</div>}
    </>
  );
}
