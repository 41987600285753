import loadable from '@loadable/component';
import * as Sentry from '@sentry/react';
import { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'app/styles/index.css';

import AppLayout from 'app/components/AppLayout';
import WatchRouteErrors from 'app/components/WatchRouteErrors';
import ApiProvider from 'core/api/ApiProvider';
import AuthProvider from 'core/auth/AuthProvider';
import Version from 'core/components/Version';
import useFirefoxScrollbarStyles from 'core/hooks/useFirefoxScrollbarStyles';

import Dashboard from './app/pages/Dashboard';

const SupplierList = loadable(() => import('supplier/pages/SupplierList'));
const CompanyList = loadable(() => import('company/pages/CompanyList'));
const UserList = loadable(() => import('user/pages/UserList'));
const SupplierDetail = loadable(() => import('supplier/pages/SupplierDetail'));
const Profile = loadable(() => import('app/pages/Profile'));
const Login = loadable(() => import('app/pages/Login'));
const ForgotPassword = loadable(() => import('app/pages/ForgotPassword'));
const ResetPassword = loadable(() => import('app/pages/ResetPassword'));

const App: FC = () => {
  useFirefoxScrollbarStyles();

  return (
    <BrowserRouter>
      <WatchRouteErrors />
      <Version>
        <AuthProvider>
          <ApiProvider>
            <Routes>
              {/* Unauthorized routes */}
              <Route element={<Login />} path="/login" />
              <Route element={<ForgotPassword />} path="/forgot-password" />
              <Route element={<ResetPassword />} path="/reset-password/:token" />

              {/* Authorized routes */}
              <Route element={<AppLayout />} path="/">
                <Route index element={<Dashboard />} />
                <Route element={<Profile />} path="profile" />
                <Route element={<SupplierList />} path="suppliers" />
                <Route element={<SupplierDetail />} path="suppliers/:supplierId/*" />
                <Route element={<CompanyList />} path="companies" />
                <Route element={<UserList />} path="users" />
              </Route>
            </Routes>

            <ToastContainer
              closeOnClick
              draggable
              hideProgressBar
              autoClose={3000}
              closeButton={false}
              position="top-right"
            />
          </ApiProvider>
        </AuthProvider>
      </Version>
    </BrowserRouter>
  );
};

const AppComponent = Sentry.withProfiler(App);

export default AppComponent;
