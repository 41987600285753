import React from 'react';
import DolphinGif from 'app/assets/dolphin.gif';
import DolphinSlowGif from 'app/assets/dolphin-slow.gif';
import t from 'core/helpers/t';

interface SpinnerProps {
  fullscreen?: boolean;
  isFast?: boolean;
}

export default function Spinner({ fullscreen = false, isFast = false }: SpinnerProps) {
  return fullscreen ? (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-60 backdrop-blur-sm z-50">
      <img src={isFast ? DolphinGif : DolphinSlowGif} alt={t('Načítavám...')} className="animate-pulse w-24 h-24" />
    </div>
  ) : (
    <div className="relative flex items-center justify-center">
      <div className="absolute inset-0 bg-opacity-60 backdrop-blur-sm z-50"></div>
      <img
        src={isFast ? DolphinGif : DolphinSlowGif}
        alt={t('Načítavám...')}
        className="animate-pulse w-16 h-16 z-50"
      />
    </div>
  );
}
