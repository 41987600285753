import { useEffect } from 'react';

export default function useFirefoxScrollbarStyles() {
  useEffect(() => {
    const isFirefox = typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase().includes('firefox');
    if (isFirefox) {
      const style = document.createElement('style');
      style.textContent = `
        /* Set scrollbar thumb and track colors */
        html {
          scrollbar-color: #737373 #f1f1f1; /* Thumb and track colors */
          scrollbar-width: thin; /* Default thin scrollbar */
        }

        /* Hover effect */
        html:hover {
          scrollbar-width: auto; /* Makes scrollbar wider on hover */
        }

        /* Add smooth transitions */
        html,
        html * {
          transition: scrollbar-width 0.3s ease;
        }
      `;
      document.head.appendChild(style);
    }
  }, []);
}
