import * as Sentry from '@sentry/react';

const { VITE_APP_SENTRY_DSN, VITE_APP_ENVIRONMENT, VITE_APP_VERSION, VITE_APP_API_PATH } = import.meta.env;

// Sentry Initialization
export default function sentryInit() {
  if (!VITE_APP_SENTRY_DSN || VITE_APP_ENVIRONMENT === 'localhost') {
    return;
  }

  Sentry.init({
    dsn: VITE_APP_SENTRY_DSN,
    sampleRate: 1,
    integrations: [
      Sentry.browserProfilingIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    profilesSampleRate: 1,
    // Tracing
    tracesSampleRate: 1, // Capture 100% of the transactions
    tracePropagationTargets: VITE_APP_API_PATH.startsWith('http') ? [VITE_APP_API_PATH] : undefined,
    // Session Replay
    replaysSessionSampleRate: 0.1, // 10% sample rate in production
    replaysOnErrorSampleRate: 1, // 100% sample rate for error sessions
    debug: false,
    release: VITE_APP_VERSION || '0.0.1',
    environment: VITE_APP_ENVIRONMENT,
  });
}
