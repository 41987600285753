import * as Sentry from '@sentry/react';
import { PropsWithChildren } from 'react';

import Fallback from './Fallback';

export default function SentryWrapper({ children }: PropsWithChildren) {
  return (
    <Sentry.ErrorBoundary showDialog fallback={<Fallback />}>
      {children}
    </Sentry.ErrorBoundary>
  );
}
